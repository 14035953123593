// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SelectInput,
  required,
} from 'react-admin'

const PropertyEdit = (props) => (
  <Edit {...props} mutationMode='optimistic'>
    <SimpleForm>
      <TextInput disabled label='Id' source='id' />
      {/* <TextInput source='name' validate={required()} /> */}
      <SelectInput
        source='listedFor'
        allowEmpty={false}
        choices={[
          { id: 'sale', name: 'Sale' },
          { id: 'rent', name: 'Rent' },
        ]}
      />

      <TextInput source='bedrooms' type='number' />
      <TextInput source='bathrooms' type='number' />
      <TextInput source='totalArea' type='number' />
      <TextInput source='price' type='number' />
      <TextInput multiline source='description' />
      <DateInput disabled label='Created At' source='createdAt' />
    </SimpleForm>
  </Edit>
)

export default PropertyEdit
