// in src/posts.js
import * as React from 'react'
import { Edit, SimpleForm, TextInput, DateInput, required } from 'react-admin'

const NeighborhoodEdit = (props) => (
  <Edit {...props} mutationMode='optimistic'>
    <SimpleForm>
      <TextInput disabled label='Id' source='id' />
      <TextInput source='name' validate={required()} />
      <TextInput multiline source='description' />
      <DateInput disabled label='Created At' source='createdAt' />
    </SimpleForm>
  </Edit>
)

export default NeighborhoodEdit
