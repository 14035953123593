import axios from 'axios'

const authProvider = {
  login: async ({ username, password }) => {
    const res = await axios.post('/auth/signin', {
      email: username,
      password,
    })

    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText)
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res.data.data))
      localStorage.setItem('accessToken', res.data.accessToken)
    }
  },
  logout: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('currentUser')
    return Promise.resolve()
  },

  checkAuth: () => {
    return localStorage.getItem('accessToken')
      ? Promise.resolve()
      : Promise.reject()
  },

  getPermissions: () => {
    if (
      !localStorage.getItem('accessToken') ||
      !localStorage.getItem('currentUser')
    )
      return Promise.reject()

    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const role = currentUser.role
    return role ? Promise.resolve(role) : Promise.reject()
  },

  getIdentity: () => {
    try {
      const { id, name, email } = JSON.parse(localStorage.getItem('currentUser'))
      return Promise.resolve({ id, fullName: name, email })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default authProvider
