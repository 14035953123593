// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SelectInput,
  required,
} from 'react-admin'

const CategoryEdit = (props) => (
  <Edit {...props} mutationMode='optimistic'>
    <SimpleForm>
      <TextInput disabled label='Id' source='id' />
      <TextInput source='name' validate={required()} />
      <TextInput multiline source='description' />
      <DateInput disabled label='Created At' source='createdAt' />
    </SimpleForm>
  </Edit>
)

export default CategoryEdit
