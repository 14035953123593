import axios from 'axios'

export default {
  getList: async (resource, params) => {
    const res = await axios.get(`${resource}`)
    return {
      total: res.data.data.length,
      data: res.data.data.map((i) => {
        var doc = i
        i.id = i._id
        return doc
      }),
    }
  },
  getOne: async (resource, params) => {
    const res = await axios.get(`${resource}/${params.id}`)
    return res.data
  },
  getMany: (resource, params) => Promise,
  getManyReference: (resource, params) => Promise,
  create: async (resource, params) => {
    const res = await axios.post(`${resource}`, params.data)
    return { ...res.data.data, id: res.data.data._id }
  },
  update: async (resource, params) => {
    const res = await axios.put(`${resource}/${params.id}`, params.data)
    return { ...res.data.data, id: res.data.data._id }
  },
  updateMany: (resource, params) => Promise,
  delete: async (resource, params) => {
    const res = await axios.delete(`${resource}/${params.id}`)
    return res.data
  },

  deleteMany: (resource, params) => Promise,
}
