import axios from 'axios'
import dataProvider from './providers/dataProvider'
import authProvider from './providers/authProvider'
import { Admin, Resource } from 'react-admin'

import {
  MdPerson,
  MdPersonOutline,
  MdHome,
  MdOutlineHome,
  MdCategory,
  MdOutlineCategory,
} from 'react-icons/md'

import { BiBuildingHouse } from 'react-icons/bi'
import { GiModernCity } from 'react-icons/gi/'
import { GrMapLocation } from 'react-icons/gr/'

import UserList from './pages/dashboard/user/UserList'

import CategoryList from './pages/dashboard/category/CategoryList'
import CategoryCreate from './pages/dashboard/category/CategoryCreate'
import CategoryDetail from './pages/dashboard/category/CategoryDetail'
import CategoryEdit from './pages/dashboard/category/CategoryEdit'
import PropertyList from './pages/dashboard/property/PropertyList'

import PropertyDetail from './pages/dashboard/property/PropertyDetail'
import PropertyEdit from './pages/dashboard/property/PropertyEdit'
import UserDetail from './pages/dashboard/user/UserDetail'
import UserEdit from './pages/dashboard/user/UserEdit'

import CityList from './pages/dashboard/city/CityList'
import CityCreate from './pages/dashboard/city/CityCreate'
import CityDetail from './pages/dashboard/city/CityDetail'
import CityEdit from './pages/dashboard/city/CityEdit'

import NeighborhoodCreate from './pages/dashboard/neighborhood/NeighborhoodCreate'
import NeighborhoodDetail from './pages/dashboard/neighborhood/NeighborhoodDetail'
import NeighborhoodEdit from './pages/dashboard/neighborhood/NeighborhoodEdit'
import NeighborhoodList from './pages/dashboard/neighborhood/NeighborhoodList'

function App() {
  axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`
  axios.defaults.headers.get['Accept'] = 'application/json'
  axios.defaults.headers.post['Accept'] = 'application/json'
  axios.defaults.headers.put['Accept'] = 'application/json'
  axios.defaults.validateStatus = false
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  return (
    <Admin
      title='Real.et Dashboard'
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        icon={MdPerson}
        name='users'
        list={UserList}
        show={UserDetail}
        edit={UserEdit}
      />
      <Resource
        icon={MdCategory}
        name='categories'
        list={CategoryList}
        create={CategoryCreate}
        show={CategoryDetail}
        edit={CategoryEdit}
      />
      <Resource
        icon={BiBuildingHouse}
        name='properties'
        list={PropertyList}
        show={PropertyDetail}
        edit={PropertyEdit}
      />
      <Resource
        icon={GiModernCity}
        name='cities'
        list={CityList}
        create={CityCreate}
        show={CityDetail}
        edit={CityEdit}
      />

      <Resource
        icon={GrMapLocation}
        name='neighborhoods'
        list={NeighborhoodList}
        create={NeighborhoodCreate}
        show={NeighborhoodDetail}
        edit={NeighborhoodEdit}
      />
    </Admin>
  )
}

export default App
