import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  DeleteButton,
} from 'react-admin'

const NeighborhoodList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='name' />
        <TextField source='description' />
        <DateField source='createdAt' />
        <ShowButton basePath='/neighborhoods' />
        <EditButton basePath='/neighborhoods' />
        <DeleteButton basePath='/neighborhoods' mutationMode='optimistic' />
      </Datagrid>
    </List>
  )
}

export default NeighborhoodList
