// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin'

const CityCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' />
      {/* <ImageInput source='image' accept='image/*' labelSingle='image' /> */}
      <TextInput source='description' options={{ multiLine: true }} />
    </SimpleForm>
  </Create>
)

export default CityCreate
