import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  DeleteButton,
  ReferenceField,
  FunctionField,
} from 'react-admin'

const CategoryList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' />
        <ReferenceField
          label='Name'
          source='id'
          reference='categories'
          link='show'
        >
          <>
            <FunctionField
              render={(record) => <img src={record.icon} width={25} />}
            />

            <TextField source='name' />
          </>
        </ReferenceField>
        <TextField source='description' />
        <DateField source='createdAt' />
        <ShowButton basePath='/categories' />
        <EditButton basePath='/categories' />
        <DeleteButton basePath='/categories' mutationMode='optimistic' />
      </Datagrid>
    </List>
  )
}

export default CategoryList
