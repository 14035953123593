// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  SimpleShowLayout,
  Show,
  RichTextField,
} from 'react-admin'

const PropertyDetail = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <TextField source='price' />
      <TextField source='listedFor' />
      <TextField source='bedrooms' />
      <TextField source='bathrooms' />
      <TextField source='totalArea' />
      <RichTextField source='description' />
      <DateField label='Created At' source='createdAt' />
    </SimpleShowLayout>
  </Show>
)

export default PropertyDetail
