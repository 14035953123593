import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  EditButton,
  DeleteButton,
  FunctionField,
  ReferenceField,
} from 'react-admin'

const PropertyList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <FunctionField
          label='Property'
          render={(record) => (
            <>
              <ReferenceField
                label='Proper'
                source='id'
                reference='properties'
                link='show'
              >
                <>
                  <div>
                    <b>{record.category.name}</b>
                  </div>
                </>
              </ReferenceField>
              <div>{record.bedrooms} bedrooms</div>
              <div>{record.bathrooms} bathrooms</div>
              <div>
                {record.totalArea} {record.totalAreaUnit}{' '}
              </div>
              <div>
                <i>{record.description}</i>
              </div>
            </>
          )}
        />
        <FunctionField
          label='Category'
          render={(record) => (
            <ChipField record={{ name: record.category.name }} source='name' />
          )}
        />
        <FunctionField
          label='Listed For'
          render={(record) => (
            <ChipField
              source='listedFor'
              color={record.listedFor == 'sale' ? 'primary' : 'secondary'}
            />
          )}
        />
        <FunctionField
          label='Price'
          render={(record) => (
            <>
              {record.priceCurrency} {record.price}
            </>
          )}
        />
        <DateField source='createdAt' />
        <EditButton basePath='/properties' />
        <DeleteButton basePath='/properties' mutationMode='optimistic' />
      </Datagrid>
    </List>
  )
}

export default PropertyList
