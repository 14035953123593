// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SelectInput,
  required,
} from 'react-admin'

const UserEdit = (props) => (
  <Edit {...props} mutationMode='optimistic'>
    <SimpleForm>
      <TextInput disabled label='Id' source='id' />
      <TextInput source='name' validate={required()} />
      <SelectInput
        source='role'
        allowEmpty={false}
        choices={[
          { id: 'regular', name: 'Regular' },
          { id: 'super-admin', name: 'Super Admin' },
          { id: 'admin', name: 'Admin' },
          { id: 'editor', name: 'Editor' },
        ]}
      />

      <TextInput source='email' type='email' validate={required()} />
      <TextInput source='googleId' />

      <DateInput disabled label='Created At' source='createdAt' />
    </SimpleForm>
  </Edit>
)

export default UserEdit
