// in src/posts.js
import * as React from 'react'
import {
  TextField,
  DateField,
  SimpleShowLayout,
  Show,
  RichTextField,
} from 'react-admin'

const CityDetail = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <RichTextField source='description' />
      <DateField label='Created At' source='createdAt' />
    </SimpleShowLayout>
  </Show>
)

export default CityDetail
