// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  SimpleShowLayout,
  Show,
  RichTextField,
} from 'react-admin'

const UserDetail = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <TextField source='email' />
      <TextField source='role' />
      <TextField source='googleId' />
      <TextField source='emailVerifiedAt' />
      <TextField source='lastSignin' />
      <DateField label='Created At' source='createdAt' />
    </SimpleShowLayout>
  </Show>
)

export default UserDetail
