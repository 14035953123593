// in src/posts.js
import * as React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  required,
  ImageInput,
} from 'react-admin'

const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' />
      {/* <ImageInput source='image' accept='image/*' labelSingle='image' /> */}
      <TextInput source='description' options={{ multiLine: true }} />
    </SimpleForm>
  </Create>
)

export default CategoryCreate
